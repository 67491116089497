body {
  background-color: #202d3d;
}

.navbar {
  background-color: #333;
  color: #fff;
}

.navbar-brand {
  font-weight: bold;
  color: #fff;
}

.navbar-nav .nav-link {
  color: #fff;
}

.active {
  font-weight: bold;
}

body.dark-mode {
  background-color: #333;
  color: #fff;
}

body.dark-mode .navbar {
  background-color: #fff;
  color: #333;
}

body.dark-mode .navbar-brand {
  color: #333;
}

body.dark-mode .navbar-nav .nav-link {
  color: #333;
}

/* container and card style */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: #202d3d;
  color: #ffffff;
  gap: 10px;
}

.card-item-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: flex-start;
}

.card-item {
  background-color: #2e3e4f;
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
  transition: transform 0.2s;
  display: flex; /* Set .card-item as a flex container */
  flex-direction: column; /* Stack children vertically */
  align-items: flex-start; /* Center children horizontally within the container */
  justify-content: center; /* Optional: Adjust this to space children as desired */
}

.card-item:hover {
  transform: scale(1.06);
}

.card-item h3,
.card-item p {
  margin: 5px 0;
  text-align: center; /* Center-align the text of h3 and p elements */
}

.card-item button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
}

.card-item button:hover {
  opacity: 0.8;
}
/* end of container */

/* FormStyles.css */
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #202d3d;
  min-height: 100vh;
  color: #fff;
}

.form-container form {
  width: 300px;
}

.form-container label {
  display: block;
  margin-bottom: 5px;
}

.form-container select,
.form-container input {
  width: 100%;
  margin-bottom: 5px;
  padding: 5px;
}

.form-container button {
  background-color: #fff;
  color: #333;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}
