/* LoaderModal.css */
.loader-modal {
  position: fixed; /* Fixed position to cover the whole screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* High z-index to ensure it's above other content */
}

.loader {
  /* Styling for your loader here */
  /* This is a placeholder style. Customize as needed or replace with your loader */
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
}
